import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Select } from '@veneer/core'

export const ActionSelect = styled(Select)<{ isWexTheme?: boolean }>`
  && {
    min-width: 224px;
    background-color: white;
    border-radius: ${({ isWexTheme }) =>
      isWexTheme
        ? `${primitives.layout.cornerRadius2}px `
        : `${primitives.layout.cornerRadius5}px `};
    @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
      min-width: initial;
      width: 100%;
    }
  }
`

export const ProxyName = styled.span`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`

export const ProxyStatus = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
`
export const ClickToAddProxy = styled.div`
  margin-top: 8px;
`

/* istanbul ignore next */
export const WxStatus = styled.div<{ status: string; userThemeMode: string }>`
  align-items: center;
  background-color: ${({ status }) =>
    status.toLowerCase() === 'online' ? '#007D48' : '#CC2C00'};
  border-radius: ${primitives.layout.cornerRadius1}px;
  color: ${({ userThemeMode }) =>
    userThemeMode === 'dark' ? '#212121' : '#ffffff'};
  display: flex;
  font-size: 12px;
  line-height: 14px;
  height: 24px;
  padding: ${primitives.layout.size1}px ${primitives.layout.size3}px
    ${primitives.layout.size1}px ${primitives.layout.size2}px;
  width: 78px;
`

export const StatusLabel = styled.span`
  margin-top: 1px;
  font-size: ${primitives.typography?.size0};
  font-weight: ${primitives.typography?.weight4};
  line-height: ${primitives.typography?.lineHeight1};
`
export const ColumnPiece = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;
  align-items: center;
  white-space: nowrap;
`

export const ContextualMenuArea = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
`

export const UserInfo = styled.div`
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ReorderColumnPortal = styled.div`
  .vn-modal--footer div {
    flex-direction: row-reverse;
    gap: 12px;
  }
`
export const HighlightedMark = styled.mark<{ isWexTheme: boolean }>`
  background-color: ${(props) =>
    props.isWexTheme ? primitives.color.darkNavy1 : primitives.color.hpBlue3};
`

export const TableContent = styled.div`
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
