import React, { forwardRef, useRef, useState } from 'react'
import { Tooltip } from '@veneer/core'
import { HighlightedMark, TableContent } from './styles'

type TableEllipsisTooltipProps = {
  name: string
  parts: string[]
  isWexTheme: boolean
  dataTestId: string
  searchKey: string
}

const TableContentRef = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => (
  <TableContent
    ref={ref}
    {...props}
  />
))

TableContentRef.displayName = 'TableContentRef'
const TableEllipsisTooltip: React.FC<TableEllipsisTooltipProps> = ({
  name,
  parts,
  isWexTheme,
  dataTestId,
  searchKey
}) => {
  const columnRef = useRef<HTMLDivElement | null>(null)
  const [renderTooltip, setRenderTooltip] = useState(false)

  const trackEllipsis = () => {
    const el = columnRef.current
    const isEllipsis = el.scrollWidth > el.clientWidth
    setRenderTooltip(isEllipsis)
  }

  const renderContent = () => (
    <TableContentRef
      ref={columnRef}
      data-testid={dataTestId}
      onMouseEnter={trackEllipsis}
      onMouseLeave={() => setRenderTooltip(false)}
    >
      {parts.map((part, index) =>
        part.toLowerCase() === searchKey.toLowerCase() ? (
          <HighlightedMark
            isWexTheme={isWexTheme}
            key={index}
          >
            {part}
          </HighlightedMark>
        ) : (
          part
        )
      )}
    </TableContentRef>
  )

  return renderTooltip ? (
    <Tooltip
      arrow
      content={name}
      contentHideDelayOnHover={300}
      contentShowDelayOnHover={300}
      data-testId={`${dataTestId}-tooltip`}
      id={`${dataTestId}-tooltip`}
      placement="bottom-start"
      portal
      useJsPositioning
    >
      {renderContent()}
    </Tooltip>
  ) : (
    renderContent()
  )
}

export default TableEllipsisTooltip
