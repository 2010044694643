/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProxyList } from 'src/interfaces/proxyInterface'
import { FleetProxieListProps } from '../components/ProxiesMainPage/type'
import { ProxyStatusValues, TIME_CONTSTANTS } from './constants'
import dayjs from 'dayjs'
import primitives from '@veneer/primitives'
let localprops: FleetProxieListProps = {
  language: 'en',
  country: 'US'
}
export const getDownloadUrlWorkforce = (authStack, pathname) => {
  if (authStack === 3) {
    return 'https://downloads.hpdaas.com/production/fleet-proxy-software/installer/latestversion/WXP.PrintFleetProxy.msi'
  } else if (pathname.includes('usdev')) {
    return 'https://downloads.hpdaas.com/master/fleet-proxy-software/installer/latestversion/WXP.PrintFleetProxy.dev.msi'
  } else {
    return 'https://downloads.hpdaas.com/staging/fleet-proxy-software/installer/latestversion/WXP.PrintFleetProxy.stage.msi'
  }
}
export const setProps = (props) => {
  localprops = props
}

export const getProps = (): FleetProxieListProps => {
  return localprops
}

export const filter = (
  proxyListData: Array<ProxyList>,
  searchItem: string,
  searchItems: string[],
  t?: (key: string, defaultValue: string) => string
) => {
  if (searchItem.length === 0 && searchItems.length === 0) {
    return proxyListData
  }
  const filterdata = proxyListData
    .filter((proxy) => {
      if (searchItem.length != 0) {
        const dateAdded = getFormatedDate(proxy.dateAdded, t, true)
        const lastUpdated = getFormatedDate(proxy.lastUpdated, t, true)
        return (
          proxy?.proxyName?.toLowerCase().includes(searchItem.toLowerCase()) ||
          proxy?.connectionState.status
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          proxy?.hostName?.toLowerCase().includes(searchItem.toLowerCase()) ||
          proxy?.description
            ?.toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          proxy?.devices?.toString().includes(searchItem) ||
          dateAdded
            .toString()
            ?.toLowerCase()
            ?.includes?.(searchItem.toLowerCase()) ||
          lastUpdated
            .toString()
            ?.toLowerCase()
            ?.includes?.(searchItem.toLowerCase()) ||
          proxy?.softwareVersion?.toString().includes(searchItem.toLowerCase())
        )
      } else {
        return proxy
      }
    })
    .filter((proxy) => {
      if (searchItems.length != 0) {
        if (searchItems.some((item) => ProxyStatusValues.includes(item))) {
          return searchItems.some((value) =>
            proxy?.connectionState.status.toLowerCase().includes(value)
          )
        } else {
          return proxy
        }
      } else {
        return proxy
      }
    })
  return filterdata
}

export const selectRow = (
  dataArray: ProxyList[],
  checked: boolean,
  id?: number
) => {
  const updatedDataArray = [...dataArray]
  const index = updatedDataArray.findIndex((row) => row.proxyId === id)
  updatedDataArray[index].rowConfig.selected = checked
  return updatedDataArray
}

export const handleMultipleProxies = (
  dataArray: ProxyList[],
  checked: boolean
) => {
  return dataArray.map((row) => {
    const newRow = row
    newRow.rowConfig.selected = checked
    return newRow
  })
}

export const selectionState = (dataArray: ProxyList[]) => {
  const total = dataArray.length
  const selected = dataArray.filter((row) => row.rowConfig.selected).length

  switch (selected) {
    case 0:
      return 'none'
    case total:
      return 'all'
    default:
      return 'indeterminated'
  }
}

export const sortTable = (object1, object2, id, value) => {
  let fieldA = object1[id]
  let fieldB = object2[id]

  if (typeof fieldA === 'string' && typeof fieldB === 'string') {
    if (!isNaN(Date.parse(fieldA)) && !isNaN(Date.parse(fieldB))) {
      fieldA = new Date(fieldA)
      fieldB = new Date(fieldB)
    } else {
      fieldA = fieldA.toLowerCase()
      fieldB = fieldB.toLowerCase()
    }
  } else if (
    (fieldA === null || fieldB === null) &&
    typeof fieldA !== typeof fieldB
  ) {
    if (fieldA === null) return -value
    if (fieldB === null) return value
  }
  if (!isNaN(fieldA) && !isNaN(fieldB)) {
    fieldA = Number(fieldA)
    fieldB = Number(fieldB)
  }

  if (fieldB < fieldA) {
    return -value
  }
  if (fieldB > fieldA) {
    return value
  }
  return 0
}

export const getFormatedDate = (
  date: string,
  t?: (key: string, defaultValue: string) => string,
  showRichFormat?: boolean,
  mockNewDate?: string
) => {
  if (!date) {
    return ''
  }

  const currentDate = mockNewDate ? new Date(mockNewDate) : new Date()
  const timeDiff = (currentDate.getTime() - new Date(date).getTime()) / 1000

  const receivedJustNow = () => timeDiff < TIME_CONTSTANTS['1_MINUTE']
  const receivedOneMinuteAgo = () =>
    timeDiff >= TIME_CONTSTANTS['1_MINUTE'] &&
    timeDiff < TIME_CONTSTANTS['2_MINUTES']
  const recievedFiftyNineMinutesAgo = () =>
    timeDiff >= TIME_CONTSTANTS['2_MINUTES'] &&
    timeDiff < TIME_CONTSTANTS['1_HOUR']
  const recievedOneHourAgo = () =>
    timeDiff >= TIME_CONTSTANTS['1_HOUR'] &&
    timeDiff < TIME_CONTSTANTS['2_HOURS']
  const receivedTwentyThreeHoursAgo = () =>
    timeDiff >= TIME_CONTSTANTS['2_HOURS'] &&
    timeDiff < TIME_CONTSTANTS['1_DAY']
  const receivedYestrday = () =>
    timeDiff >= TIME_CONTSTANTS['1_DAY'] && timeDiff < TIME_CONTSTANTS['2_DAYS']
  const receivedSixDaysAgo = () =>
    timeDiff >= TIME_CONTSTANTS['2_DAYS'] &&
    timeDiff < TIME_CONTSTANTS['1_WEEK']

  const renderNonRichFormat = () => {
    const formatedDate = dayjs(date).format('DD MMM, YYYY hh:mm A')
    return formatedDate
  }

  if (showRichFormat) {
    {
      if (receivedJustNow()) {
        return `${t('timeFormats.justNow', 'Just Now')}`
      } else if (receivedOneMinuteAgo()) {
        return `${t('timeFormats.oneMinuteAgo', '1 minute ago')}`
      } else if (recievedFiftyNineMinutesAgo()) {
        return `${t('timeFormats.fiftyNineMinutesAgo', '59 minutes ago')}`
      } else if (recievedOneHourAgo()) {
        return `${t('timeFormats.oneHourAgo', '1 hour ago')}`
      } else if (receivedTwentyThreeHoursAgo()) {
        return `${t('timeFormats.twentyThreeHoursAgo', '23 hours ago')}`
      } else if (receivedYestrday()) {
        return `${t('timeFormats.yesterday', 'Yesterday')}`
      } else if (receivedSixDaysAgo()) {
        return `${t('timeFormats.sixDaysAgo', '6 days ago')}`
      } else {
        return renderNonRichFormat()
      }
    }
  } else {
    return renderNonRichFormat()
  }
}

export const jsonParse = (text: string, defResult = []) => {
  try {
    return text ? JSON.parse(text) : defResult
  } catch (SyntaxError) {
    return defResult
  }
}

export function fixTheme(themeProps) {
  let props = themeProps
  // Newer Veneer expects background container object, not just one color as it was before v3.105
  const light = props.customSemantics?.color?.light?.background?.container
  if (light && !light.default) {
    // fix light theme if needed
    props = {
      ...props,
      customSemantics: {
        ...props.customSemantics,
        color: {
          ...props.customSemantics.color,
          light: {
            ...props.customSemantics.color.light,
            background: {
              ...props.customSemantics.color.light.background,
              container: {
                default: `${primitives.color.white}`,
                hover: `${primitives.color.gray1}`,
                active: `${primitives.color.gray2}`
              }
            }
          }
        }
      }
    }
  }

  const dark = props.customSemantics?.color?.dark?.background?.container

  if (dark && !dark.default) {
    // fix dark theme if needed
    props = {
      ...props,
      customSemantics: {
        ...props.customSemantics,
        color: {
          ...props.customSemantics.color,
          dark: {
            ...props.customSemantics.color.dark,
            background: {
              ...props.customSemantics.color.dark.background,
              container: {
                default: `${primitives.color.gray11}`,
                hover: `${primitives.color.gray10}`,
                active: `${primitives.color.gray9}`
              }
            }
          }
        }
      }
    }
  }

  return props
}

export const fetchListForDemoMode = async (
  apiPath,
  requestOptions,
  mockNoDevices: boolean
) => {
  let url = ''

  if (!mockNoDevices) {
    url = apiPath('demo', 'printMfeCache', 'printProxies_list')
  } else {
    url = apiPath('demo', 'printMfeCache', 'printProxies_list_nodevice')
  }

  let data = { content: [] }

  await fetch(url, {
    method: 'GET',
    headers: requestOptions(null, null, false, false)
  })
    .then(async (response) => {
      data = await response.json()
    })
    .catch((e) => {
      console.log(
        'Fleet Proxies > Demo mode > Offline > Error fetching list data',
        e
      )
    })

  return data.content
}

type ValidateDataType = {
  data: {
    user_code: string
    status: string
    device_info: string
  }
}

export const validateCodeForDemoMode = async (
  apiPath,
  requestOptions
): Promise<ValidateDataType> => {
  const url = apiPath('demo', 'printMfeCache', 'printProxies_validate_code')

  let data = { data: { user_code: '', status: '', device_info: '' } }

  await fetch(url, {
    method: 'GET',
    headers: requestOptions(null, null, false, false)
  })
    .then(async (response) => {
      data = { data: await response.json() }
    })
    .catch((e) => {
      console.log(
        'Fleet Proxies > Demo mode > Offline > Error fetching list data',
        e
      )
    })

  return data
}

export const mockOnDeleteProxy = (
  proxyData,
  selectedItems,
  setProxyData,
  openToast,
  setShowDeleteModal
) => {
  const deletedProxy = proxyData.filter(
    (proxy) => proxy.id !== selectedItems[0].id
  )

  setProxyData(proxyData.filter((proxy) => proxy.id !== selectedItems[0].id))

  if (deletedProxy.length !== 0) {
    openToast(
      'removeProxy',
      selectedItems[0].proxyName,
      selectedItems.length,
      false
    )

    setShowDeleteModal(false)
  }
}

export const mockOnUpdatedProxy = (
  proxyData,
  selectedItems,
  getTableData,
  proxyName,
  description,
  setProxyData,
  openToast,
  setShowUpdateModal
) => {
  const updatedProxy = proxyData.filter(
    (proxy) => proxy.id === selectedItems[0].id
  )

  updatedProxy[0].name = getTableData(proxyName, 'proxy-name')

  updatedProxy[0].pDescription = getTableData(description, 'proxy-description')

  updatedProxy[0].rowConfig.selected = false

  setProxyData([
    ...proxyData.filter((proxy) => proxy.id !== selectedItems[0].id),
    ...updatedProxy
  ])

  openToast('updateProxy', proxyName, selectedItems.length, false)

  setShowUpdateModal(false)
}
export const getDownloadUrl = (authStack: number): string => {
  return authStack === 1
    ? 'https://hp.sharepoint.com/teams/WSST-IntelligentAutomation/_layouts/15/download.aspx?SourceUrl=%2Fteams%2FWSST%2DIntelligentAutomation%2FShared%20Documents%2FFleet%20Proxy%20program%2FDownloads%2FFleetProxyInstaller%2FHP%2EFleetProxy%2ECC%2E1%2E0%2E0%2Drc%2E40%2Epie%2Emsi'
    : authStack === 2
      ? 'https://hp.sharepoint.com/teams/WSST-IntelligentAutomation/_layouts/15/download.aspx?SourceUrl=%2Fteams%2FWSST%2DIntelligentAutomation%2FShared%20Documents%2FFleet%20Proxy%20program%2FDownloads%2FFleetProxyInstaller%2FHP%2EFleetProxy%2ECC%2E1%2E0%2E0%2Drc%2E40%2Estage%2Emsi'
      : 'https://hp.sharepoint.com/teams/WSST-IntelligentAutomation/_layouts/15/download.aspx?SourceUrl=%2Fteams%2FWSST%2DIntelligentAutomation%2FShared%20Documents%2FFleet%20Proxy%20program%2FDownloads%2FFleetProxyInstaller%2FHP%2EFleetProxy%2ECC%2E1%2E0%2E0%2Drc%2E40%2Eprod%2Emsi'
}
